<template>
  <div class="flex flex-col flex-1 overflow-hidden bg-white border border-gray-300 rounded">

    <PLoader v-if="isLoading" />

    <POrderGridTable          
      v-else
      :cols="cols"
      :filters="filters"
      :orders="containers"        
      :totalCount="containers.length"
      :loading="isLoading"
      :expandable="false"
      :showFooter="false"          
      @click="onClick"      
    >
    </POrderGridTable>

    <portal to="filters">
      <POrderFilters v-model="filters" />
    </portal>

    <ContainerDialog
      v-if="containerDialogVisible"
      @close="onCloseContainerDialog"
      :id="selectedContainerId"
    />

  </div>
</template>

<script>

import http from "@/http"
import { mapGetters } from "vuex"
// import { last, forEach } from "lodash"
import POrderGridTable from "@/components/ordergrid/POrderGridTable"
import ContainerDialog from "../dialogs/ContainerDialog"

export default {
  
  props: {
    id: {
      type: String,
      required: false
    }
  },

  components: {
    POrderGridTable,
    ContainerDialog
  },

  data () {
    return {
      isLoading: false,
      containers: [],
      containerDialogVisible: false,
      selectedContainerId: "",
      cols: [        
        { text: "ID", value: "id", component: "p-display-text", width: 110, align: "center", },
        { text: "Ordre ID", value: "containerOrderId", component: "p-display-text", width: 110, align: "center", },
        { text: "Type", value: "containerTypeName", component: "p-display-text", align: "left" },        
        { text: "Lagerlokasjon", value: "locationNameCurrent", component: "p-display-text", align: "left" },        
        { text: "Referanse", value: "customerReference", component: "p-display-text", align: "left" },        
        { text: "Innhold", value: "containerContent", component: "p-display-text", align: "left" },        
        { text: "Status", value: "status", component: "p-display-container-status", align: "center", width: 110 },       
      ],      
      filters: {
      },   
      // orderBy: {
      //   field: "dateArrived",
      //   descending: true
      // },
    }
  },  

  computed: {
    
    ...mapGetters([
      "customer", 
      "location"
    ]),
    
    args () {

      return {
        locationId: this.location.id,
        filters: this.filters
      }
    }

  },

  methods: {

    onClick (container) {
      this.selectedContainerId = container.id
      this.containerDialogVisible = true
    },
   
    async onCloseContainerDialog () {
      this.containerDialogVisible = false
      await this.getContainers()
    },

    async getContainers () {

        this.isLoading = true

        try {

          this.containers = await http.get("Containers")

          // forEach(orders, order => {
          //   const lastTransaction = last(order.transactions)
          //   if (lastTransaction) {
          //     order.lastActivity = `${ this.$options.filters.date(lastTransaction.createdLocal) } - ${lastTransaction.transactionTypeName}`
          //   }
          // })

          // this.orders = orders

        } catch (error) {          
          this.$notifyError("", error.reason)
        }

        this.isLoading = false
    }

  },


  watch: {
    args: {
      handler: async function () {
        await this.getContainers ()
      }, immediate: true
    }
  }

}
</script>
